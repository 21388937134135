import React from 'react';
import injectSheet from 'react-jss';
import Helmet from 'react-helmet';

import Translate from '../../components/Translate';
import Layout from '../../components/layout';
import i18next from '../../config/i18next';
import themeColors from '../../styles/theme';

const styles = {
    content: {
        fontSize: '1.1em',
        width: '60%',
        marginLeft: '20%',
        '& h1': {
            flexBasis: '100%',
            fontSize: '2.7em',
            textAlign: 'left',
            lineHeight: '40px',
            fontWeight: '500',
            margin: '35px 0 40px 0'
        },
        '& p': {
            marginBottom: '25px'
        },
        '& li': {
            marginBottom: '10px'
        },
        '& a': {
            color: themeColors.primary,
            outline: 'none'
        }
    },
    '@media only screen and (max-width: 767px)': {
        content: {
            margin: '0 20px 0 20px',
            width: 'unset'
        }
    }
};

class QuickdrawSharingLegal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            title: ''
        };
    }

    componentDidMount() {
        this.loadTitleTranslation();
    }

    /*
     * The purpose of this function is to ensure that tranlations are loaded before we set the title in the head. React Helmet does not allow React
     * nodes for the title so we cannot use Translate component. Using i18next directly woold just load the key as i18next hasn't loaded the the json files yet.
     * So we need to keep track of the title state and update it once i18next loads translations
     */
    loadTitleTranslation() {
        i18next.loadNamespaces('prelogin_pages', (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`prelogin_pages:garmin_quickdraw_community`, { skipInterpliation: true });
            this.setState({ title: text });
        });
    }

    render() {
        const { classes } = this.props;
        const { title } = this.state;

        return (
            <Layout backgroundColor="#fff">
                <div className={classes.content}>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content="Garmin Connect is your home for the Quickdraw Community." />
                    </Helmet>
                    <div className={classes.container}>
                        <h1>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_EULA" />
                        </h1>
                        <h2>
                            (<Translate ns="prelogin_pages" content="quickdraw_legal_version" />)
                        </h2>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_application" />
                        </p>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_important_note" />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_license_terms"
                                params={{ 0: <strong /> }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_restrictions"
                                params={{ 0: <strong /> }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_map_submissions_1"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_map_submissions_2" />
                        </p>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_map_submissions_3" />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_rule_of_conduct"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <ol>
                            <li>
                                <Translate ns="prelogin_pages" content="quickdraw_legal_rule_of_conduct_list_1" />
                            </li>
                            <li>
                                <Translate ns="prelogin_pages" content="quickdraw_legal_rule_of_conduct_list_2" />
                            </li>
                            <li>
                                <Translate ns="prelogin_pages" content="quickdraw_legal_rule_of_conduct_list_3" />
                            </li>
                        </ol>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_rule_of_conduct_2" />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_liability"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_liability_1" />
                        </p>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_liability_2" />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_disclaimer"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_export_control"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_indemnity"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_terms"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_entire_agreement"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_government_end_users"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_copyright_policy"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_copyright_policy_list"
                                params={{ 0: <strong />, 1: <br /> }}
                            />
                        </p>
                        <ol>
                            <li>
                                <Translate ns="prelogin_pages" content="quickdraw_legal_copyright_policy_list_1" />
                            </li>
                            <li>
                                <Translate ns="prelogin_pages" content="quickdraw_legal_copyright_policy_list_2" />
                            </li>
                            <li>
                                <Translate ns="prelogin_pages" content="quickdraw_legal_copyright_policy_list_3" />
                            </li>
                            <li>
                                <Translate ns="prelogin_pages" content="quickdraw_legal_copyright_policy_list_4" />
                            </li>
                            <li>
                                <Translate ns="prelogin_pages" content="quickdraw_legal_copyright_policy_list_5" />
                            </li>
                            <li>
                                <Translate ns="prelogin_pages" content="quickdraw_legal_copyright_policy_list_6" />
                            </li>
                        </ol>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_copyright_policy_2" />
                        </p>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_copyright_policy_3" />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_contact"
                                params={{
                                    0: <br />,
                                    1: <a href="mailto:copyright@garmin.com">copyright@garmin.com</a>
                                }}
                            />
                        </p>
                        <p>
                            <Translate
                                ns="prelogin_pages"
                                content="quickdraw_legal_contact_mail"
                                params={{
                                    0: <br />
                                }}
                            />
                            <span>
                                DMCA Designated Agent
                                <br />
                                Attn: Legal Department
                                <br />
                                Garmin International, Inc.
                                <br />
                                1200 East 151st Street
                                <br />
                                Olathe, Kansas 66062
                            </span>
                        </p>
                        <p>
                            <Translate ns="prelogin_pages" content="quickdraw_legal_trademark" />
                        </p>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(QuickdrawSharingLegal);
